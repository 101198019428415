import { Suspense } from "react";
import { Spinner } from "components";
import { MasterProvider } from "context";
import Default from "routes/Default";

const App = () => (
    <Suspense fallback={ <Spinner /> }>
        <MasterProvider>
            <Default />
        </MasterProvider>
    </Suspense>
);

export default App;
