import { Image, Tag } from "antd";
import { Link } from "react-router-dom";
import { colorStatus } from "../../Constant";

export const tabItems = [
    { key: "item-1", label: "Sipariş Özeti" },
    { key: "item-2", label: "Ürün Bilgileri" },
    { key: "item-3", label: "Sipariş Geçmişi" }
];

export const documentTags =
[
    [ "Makbuz", "Yakında" ],
    [ "Fatura", "Yakında" ],
    [ "Kargolama", "Yakında" ],
    [ "Ödül puanı","Yakında"]
];
    
const handleStock = (record) => {
    const { name, on_sale, features, properties } = record;
    return (
        <>
            <Link
                to={ `/catalog/product-stock/${record?.id}/edit` }
                className="text-dark-75 font-weight-bold text-hover-info mb-1"
            >
                { name }
            </Link>
            { features ? (
                <>
                    { features?.width && (
                        <div className={ "font-weight-light font-size-xs" }>
                            <span>
                                <span>En : </span>
                                <span>{ features?.width } </span>
                            </span>
                        </div>
                    ) }
                    { features?.height && (
                        <div className={ "font-weight-light font-size-xs" }>
                            <span>
                                <span>Boy : </span>
                                <span>{ features?.height } </span>
                            </span>
                        </div>
                    ) }
                    { features?.shape && (
                        <div className={ "font-weight-light font-size-xs" }>
                            <span>
                                <span>Şekil : </span>
                                <span>{ features?.shape } </span>
                            </span>
                        </div>
                    ) }
                    { features?.side && (
                        <div className={ "font-weight-light font-size-xs" }>
                            <span>
                                <span>Kenar Dokuma : </span>
                                <span>{ features?.side } </span>
                            </span>
                        </div>
                    ) }
                    { features?.side === "Saçak" && (
                        <div className={ "font-weight-light font-size-xs" }>
                            <span>
                                <span>Saçak Tipi : </span>
                                <span>{ features?.webType } </span>
                            </span>
                        </div>) }
                    { features?.description && (
                        <div className={ "font-weight-light font-size-xs" }>
                            <span>
                                <span>Açıklama : </span>
                                <span>{ features?.description } </span>
                            </span>
                        </div>
                    ) }
                        
                </>
            ) : (
                <div className={ "font-weight-light text-muted" }>
                    { Object.keys(properties || {}).map((key, i) => (
                        <span key={ i }>
                            <span>{ key } : </span>
                            <span>{ properties[key] } </span>
                        </span>
                    )) }
                </div>
            ) }
            <div className="font-weight-light font-size-xs mt-3">
                <span className={ on_sale === true ? "on-sale" : "not-on-sale" }>
                    { on_sale ? "Satışta" : "Satışta Değil" }
                </span>
            </div>
        </>
    );
};

export const Columns = [
    {
        dataIndex: "id",
        defaultSortOrder: "descend",
        sorter: (a, b) => a?.id - b?.id,
        title: "ID",
        width: 40
    },
    {
        render: (_, record) => (
            <span className="symbol symbol-50px">
                <Image
                    className="product img-thumbnail"
                    preview={ false }
                    src={ record?.image }
                />
            </span>
        ),
        title: "Ü. Resmi",
        width: 80
    },
    {
        render: (_, record) => handleStock(record),
        title: "Ürün"
    },
    {
        render: (_,record) => record?.type?.name,
        title: "Tür"
    },
    {
        render: (_,record) => record?.brand?.name,
        title: "Marka"
    },
    { dataIndex:"quantity",
        title: "Adet"  
    },
    {   dataIndex:"total_cost",
        title: "Tutar"
    },
    {
        render: (_,record) => 
            <Tag color={ colorStatus[record?.status_label] }>{ record?.status_label }</Tag>,
        title: "Durum"
    }
];


